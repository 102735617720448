import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Closure from "../components/Closure";
import { HTMLContent } from "../components/Content";

const MapsButton = ({ href }) => (
  <div className="has-text-centered">
    <a href={href} target="_blank" rel="noopener noreferrer">
      <img
        src={"/img/maps-logo.png"}
        alt="maps"
        style={{ maxWidth: "100px" }}
      />
    </a>
  </div>
);

export const CollaborationsPageTemplate = () => {
  return (
    <div>
      <h1 className="is-size-2 is-hidden-tablet full-width-logo page-title-font">
        Collaborations
      </h1>
      <section className="is-hidden-touch">
        <div
          className="full-width-image-container margin-top-0 is-hidden-mobile'"
          style={{
            backgroundImage: `url('/img/page-backgrounds/collaborations.jpg')`,
          }}
        >
          <h1
            className="is-hidden-mobile page-title-font highlight"
            style={{
              padding: "4rem",
              color: "black",
            }}
          >
            Collaborations
          </h1>
        </div>
      </section>
      <section>
        {/* <Closure>
          Please be aware that Lonely Lentil will be closed from Tuesday 28th
          May to Monday 17th June 2024. We’re off on a holiday!
        </Closure> */}
      </section>
      <section>
        <div className="container">
          <div className="columns">
            <div className="column ">
              <div className="section">
                <div className="tile is-ancestor">
                  {/* <div className="tile is-12 is-parent">
                    <div className="tile is-child box"> */}
                  {/* <div className="columns"> */}
                  {/* <div className="column has-text-centered">
                          <img
                            src={"/img/colline_photo.png"}
                            alt="colline"
                            style={{ maxHeight: "500px" }}
                          />
                        </div>
                        <div className="column">
                          <div className="has-text-centered">
                            <a
                              href="
                              https://www.collineskitchen.com/catering/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={"/img/colline_logo.png"}
                                alt="collines_kitchen_logo"
                                style={{ maxWidth: "350px" }}
                              />
                            </a>
                          </div>
                          <p>
                            Lydia is delighted to provide contributions to the
                            menu at Colline’s Kitchen at{" "}
                            <a
                              href="https://www.berkshireyouth.co.uk/donate/the-waterside-centre/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              The Waterside Centre
                            </a>{" "}
                            in central Newbury. The popular Buddha bowls feature
                            an exciting mix of grains, salads and proteins…
                            always created with love and imagination between us!
                            The menu and theme of the bowls changes fortnightly.
                            We love to take inspiration from cuisines from
                            around the world, featuring spice and more unusual
                            (yet always delicious!) flavours. Gluten-free and
                            vegan options are always available.
                          </p>
                          <br />
                          <p>
                            Lydia’s offerings always feature an abundance of the
                            wonderful ingredients sold at Lonely Lentil!
                          </p>
                          <br />
                          <p>
                            We are also able to provide private catering
                            services in the local area. The food offered is
                            generous, wholesome and always delicious! Enquire
                            for details about menus for private events or
                            business lunches.
                          </p>
                          <br />
                        </div> */}
                  {/* </div> */}
                  {/* </div>
                  </div> */}
                </div>
                <div className="tile is-ancestor">
                  <div className="tile is-12 is-parent">
                    <div className="tile is-child box">
                      <div className="columns">
                        <div className="column">
                          <div className="has-text-centered">
                            <a
                              href="
                              https://www.mrsb.kitchen/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={"/img/mrs_b_logo.webp"}
                                alt="mrs_b_logo"
                                style={{ maxWidth: "250px" }}
                              />
                            </a>
                          </div>
                          <br />
                          <p>
                            Lonely Lentil and Mrs B love to find ways to
                            collaborate. You may have visited our weekly pop-up
                            shop at the cafe before March 2020. We're delighted
                            to now have Mrs B's cafe as an order collection
                            point (see{" "}
                            <a href="/delivery">Delivery Information</a>).
                            <br />
                            <br />
                            Why not collect your Lonely Lentil order for free
                            and enjoy a trip to Mrs B's? Treat yourself to one
                            of the many yummy delights on offer at the same
                            time!
                          </p>
                          <br />

                          <MapsButton href="https://g.page/MrsBKitchen?share" />
                        </div>
                        <div className="column has-text-centered">
                          <img
                            src={
                              "/img/105522175_1181120395568339_7610691984060948412_n-1-.jpg"
                            }
                            alt="us"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tile is-ancestor">
                  <div className="tile is-12 is-parent">
                    <div className="tile is-child box">
                      <div className="columns">
                        <div className="column has-text-centered">
                          <img
                            src={
                              "/img/93764733_2782129631903978_8463998011918254080_o.jpg"
                            }
                            alt="us"
                          />
                        </div>
                        <div className="column">
                          <div className="has-text-centered">
                            <a
                              href="https://www.thatchamrefillable.co.uk/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={"/img/thatcham_refillable_logo.png"}
                                alt="thatcham_refillable"
                                style={{ maxWidth: "250px" }}
                              />
                            </a>
                          </div>
                          <br />
                          <p>
                            Husband and wife team Tom and Jenny run this popular
                            local service from their home in Thatcham. A
                            wonderful range of cleaning products are available
                            for local delivery including washing detergents,
                            floor and toilet cleaners, washing-up liquid,
                            surface cleaner and much more! Personal care
                            products are also on offer, including refillable
                            shampoo and shower gels, hand soap, hand sanitiser,
                            soap bars, toilet roll and more.
                          </p>
                          <br />
                          <p>
                            <a
                              href="https://thatchamrefillable.co.uk/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Thatcham Refillable
                            </a>{" "}
                            cut your plastic bottle waste to nothing! Your
                            bottles are REFILLED and all supply containers go
                            back to the factory to be refilled and sent back to
                            them. This is a circular economy at its best.
                            <br />
                          </p>
                          <br />
                          <p>
                            You can combine your Lonely Lentil and Thatcham
                            Refillable delivery! Simply get in touch with us or
                            Thatcham Refillable and we can arrange it in the
                            most convenient way for you.
                          </p>
                          <br />
                          <MapsButton
                            href={"https://goo.gl/maps/inFnucCxnY4fEeYX9"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tile is-ancestor">
                  <div className="tile is-12 is-parent">
                    <div className="tile is-child box">
                      <div className="columns">
                        <div className="column">
                          <div className="has-text-centered">
                            <a
                              href="https://www.janineford.com/about"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={"/img/janine_logo.webp"}
                                alt="Janine"
                                style={{
                                  maxWidth: "100px",
                                }}
                              />
                            </a>
                          </div>
                          <p className="title has-text-centered tile-title">
                            Janine Ford Therapies and Yoga
                          </p>
                          <p>
                            Lydia and Janine are fellow foodies and
                            spice-fanatics! We were delighted to collaborate to
                            host a unique wine tasting and feasting event in
                            November 2021 in Upper Bucklebury. Monthly Friday
                            evening ‘Gong Baths’, combined with yoga and massage
                            are set to be taking place throughout 2022, in
                            collaboration with &nbsp;
                            <a
                              href="https://astro-gong.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Black Cygnet Wellness
                            </a>
                            . Lydia will be providing homemade cake and a
                            selection of the Lonely Lentil tea range, perfectly
                            complimenting these evenings of relaxation and
                            restoration for body and mind. These events will be
                            taking place at The Victory Room in Bucklebury.
                          </p>
                          <br />
                          <p>
                            Janine is an experienced yoga, massage and
                            reflexology professional. She offers a range of
                            amazing services locally. Most recently she has
                            taken on a treatment room at Buick Mackane in
                            central Newbury, where she offers a range of divine
                            massage and therapy treatments on a weekly basis.
                            <br />
                            <br />
                          </p>
                          <MapsButton href="https://goo.gl/maps/QsPXxPmeB4T3xmQg9" />
                        </div>
                        <div className="column has-text-centered">
                          <img
                            src={"/img/janine_photo.webp"}
                            alt="coffee"
                            style={{ maxHeight: "500px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tile is-ancestor">
                  <div className="tile is-12 is-parent">
                    <div className="tile is-child box">
                      <div className="columns">
                        <div className="column has-text-centered">
                          <img src={"/img/coffee_beans.jpg"} alt="coffee" />
                        </div>
                        <div className="column">
                          <div className="has-text-centered">
                            <a
                              href="https://www.wbroast.co.uk/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={"/img/wbr_logo.webp"}
                                alt="West_Berkshire_Roastery_Logo"
                                style={{ maxWidth: "200px" }}
                              />
                            </a>
                          </div>
                          <p>
                            We are delighted to stock wonderful organic coffee
                            beans from this award-winning coffee roaster.
                            <br />
                            <br />
                            Our beans are roasted to order for us, so your
                            coffee will always be super fresh! Alex runs West
                            Berkshire Roastery from Thatcham.
                            <br />
                            <br />
                            We are very fortunate to have such a great supplier
                            so local to us!
                            <br />
                            <br />
                          </p>
                          <MapsButton href="https://g.page/TheRunnerBeanCoffeeCo?share" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tile is-ancestor">
                  <div className="tile is-12 is-parent">
                    <div className="tile is-child box">
                      <div className="columns">
                        <div className="column">
                          <div className="has-text-centered">
                            <a
                              href="https://www.posy.london/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={"/img/posy_logo.jpg"}
                                alt="posy"
                                style={{ maxWidth: "350px" }}
                              />
                            </a>
                          </div>
                          <p>
                            PosyLondon is another eco-friendly business based in
                            Newbury. Wonderful Dale is the super-talented lady
                            behind this lovely brand of handmade natural
                            products to keep you feeling fresh and wonderful.
                            Posy deodorants come in a gorgeous range of scents
                            and are made using natural products including
                            beeswax and essential oils.
                            <br /> <br /> The deodorant comes in both cardboard
                            tubes or convenient small travel tins. The tubes
                            last 3-4 months and are brilliantly effective.
                          </p>
                          <br />
                          <p>
                            The shampoo and conditioner bars are equally
                            gorgeous in the scent department and leave your hair
                            feeling clean and nourished. The shampoo bars are
                            available in cotton bags which the product can be
                            used in... the cotton bag creates a wonderful lather
                            and increases the lifespan of the bar. The
                            conditioner bars and refill shampoo bars are
                            packaged in compostable glassine bags. The bars are
                            coconut-based and scented with essential oils.
                            <br />
                          </p>
                          <br />
                        </div>
                        <div className="column has-text-centered">
                          <img
                            src={
                              "/img/100479071_2563023723798821_5308547790803042304_o.jpg"
                            }
                            alt="us"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const CollaborationsPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <CollaborationsPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

CollaborationsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CollaborationsPage;

export const CollapborationsPageQuery = graphql`
  query CollaborationsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
